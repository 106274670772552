import React from "react";
import { StaticQuery, graphql } from "gatsby";

import Layout from "../../components/layout";
import Header from "../../components/header";
import Tabs from "../../components/tabsWhoWeAre";

const Location = () => {
  return (
    <Layout>
      <Header />
      <Tabs selection="localizacion" />

      <main className="location">
        <div className="map" />
        <div className="locations">
          <StaticQuery
            query={graphql`
              {
                allLocationsJson {
                  edges {
                    node {
                      locations {
                        faxTransfer
                        fax
                        direction
                        phone
                        province
                        city
                        zip
                      }
                    }
                  }
                }
              }
            `}
            render={(data) =>
              data.allLocationsJson.edges[0].node.locations.map(
                (currentValue, index) => (
                  <div key={index}>
                    <h3>{currentValue.province}</h3>
                    <p>{currentValue.direction}</p>
                    <p>{currentValue.phone}</p>
                    <p>{currentValue.fax}</p>
                  </div>
                )
              )
            }
          />
        </div>
      </main>
    </Layout>
  );
};

export default Location;
